import { http } from "@/utils";

// 查询单船信息
export const getSingleShip = (billCode) =>
  http.get(`/api/shipinfo/getSingleShip?ladingBillCode=${billCode}`);

// 宝船网-查询单船信息 /api/shipinfo/getBillLatestCargoAis?ladingBillId=153
export const getShip = (billId) =>
  http.get(`/api/shipinfo/getBillLatestCargoAis?ladingBillId=${billId}`);
// jk 摄像头
export const monitorJK=()=>http.get('/api/monitor/online/WHSE307703');
// jk 摄像头
export const fangJK=()=>http.get('/api/monitor/online/fang');
export const ygshJK=()=>http.get('/api/monitor/online/ygsh_store');
// 汉中
export const hzJK=()=>http.get('/api/monitor/online/WHSE182906');
export const getOnlinLists=(p)=>http.get('/api/monitor/online/'+p);
// 宝船网查询轨迹
//export const getShipTrack= p => http.get(`/api/shipinfo/getBillCargoPositionRecordV2?ladingBillId=${p.billId}`)
export const getShipTrack = (p) =>
  http.get(
    `/api/shipinfo/getBillCargoPositionRecordV2?ladingBillId=${p.billId}${(p.lineId && `&lineId=${p.lineId}`) || ""
    }${(p.startTime && `&startTime=${p.startTime}`) || ""}${(p.reachDestPortTimeInSecond &&
      `&reachDestPortTimeInSecond=${p.reachDestPortTimeInSecond}`) ||
    ""
    }`
  );

// 靠港信息
//export const getPort = p => http.get(`/api/shipinfo/getShipPortOfCall?ladingBillCode=${p.billCode}&beginTime=${p.startTime}&endTime=${p.endTime}&pageNum=${p.pageNum}&pageSize=${p.pageSize}`)

// 转港信息，出发港，目的港信息 /api/shipinfo/getBillMilestonePosition?ladingBillId=153
export const getPosition = (billId) =>
  http.get(`/api/shipinfo/getBillMilestonePosition?ladingBillId=${billId}`);

// 宝船网靠港信息
export const getPort = (billId) =>
  http.get(`/api/shipinfo/getBillCargoPortRecord?ladingBillId=${billId}`);

// 验证码
export const getVerifyCode = (p) => http.post(`/api/user/sendVerifyCode`, p);

// 注册
export const saveUser = (p) => http.post(`/api/user/register`, p);

// 查询用户信息
export const getUser = (p) =>
  http.get(`/api/market/loginInfo/userInfo`);

// 获取菜单信息
//export const loadRoleMenu = p => http.get('/api/myMenu', p.token)
//api/menu/list?pageName=my_menu
export const loadRoleMenu = (pageName) =>
  http.get(`/api/menu/list?pageName=${pageName}`);

// 向导保存
export const guideSave = (p) => http.post(`/api/user/guideSave`, p);

// 字典查询
export const queryDic = (p) =>
  http.get(`/api/dict/list?dictType=${p.type}`, p.param);

// 获取 oss 对象
export const queryOSS = (p) =>
  http.get(
    `/api/file/preUpload?fileName=${p.fileName}&fileSize=${p.fileSize}`
  );

// 上传服务
export const postUpload = (p) =>
  http.get(
    `/api/file/postUpload?bucket=${p.bucket}&objectName=${p.objectName}&signature=${p.signature}`
  );

// OCR 服务 /check/bizCertOcr
export const postOCR = (p) => http.post("/api/check/bizCertOcr", p);

// 驾驶舱
export const loadbill = (p) => http.get("/api/ladBill/getBills");

// 新增待办提醒
export const jobNews = (p) => http.get(`/api/cooperation/jobNews`);
// 查询港口信息
export const loadport = (p) => http.post("/api/dict/listByCodes", p);

// 风险预警
// export const loadRisk = (p) => http.get(`/api/riskWarn/topN?n=${p.num}`);
// 风险预警添加分页
export const loadRisk = (p) => http.post("/api/riskWarn/topN", p);
// 港口统计
export const loadPortSum = (p) => http.get(`/api/ladBill/sum`);

// 提单库存
export const loadbillStore = (p) => http.get(`/api/ladBill/billStore?searchParam=${p?.searchParam}`);
// 提单库存 可搜索
export const getbillStore = (p) => http.post(`/api/ladBill/billStore`, p);

// 审阅通过
export const pass = (p) =>
  http.get(`/api/review/pass?toDOJobId=${p.toDojobId}&msg=${p.msg}`);

// 出库审核 - 通过
export const passPost = (p) => http.post(`/api/review/passPost`, p);

// 出库审核 - 不通过
export const rejectPost = (p) => http.post(`/api/review/rejectPost`, p);

// 审阅不通过
export const reject = (p) =>
  http.get(`/api/review/reject?toDOJobId=${p.toDojobId}&msg=${p.msg}`);

// 用户审核
export const userAudit = (p) => http.post(`/api/user/audit`, p);

// 用户审核不通过
export const userAuditFail = (p) => http.post(`/api/user/auditFail`, p);

// 需求单审核
export const enquiryAduit = (p) => http.post("/api/match/enquiry/audit", p);

// 获取银行账号
export const getBank = (p) =>
  http.get(`/api/dict/list?dictType=platform_account`, p);

// 校验手机号是否注册
export const checkPhone = (p) =>
  http.get(`/api/user/checkAvailable?phoneNumber=${p.phoneNumber}`, p);

// 获取公司信息
export const getCompanyInfo = (p) =>
  http.get(`/api/company/detail?companyCode=${p.companyCode}`, p);

// 用户信息
// export const getUserById = (p) => http.get(`/api/user/getById?id=${p.id}`, p);
// 用户信息修改 /api/user/getByIdOrigin?id=10305
export const getUserById = (p) => http.get(`/api/user/getByIdOrigin?id=${p.id}`, p);
// 验证码是否正确
export const checkVerifyCode = (p) => http.post(`/api/user/checkVerifyCode`, p);

// 修改手机号
export const modifyPhone = (p) => http.post(`/api/user/modifyPhone`, p);

// 修改密码
export const modifyPwd = (p) => http.post(`/api/user/modifyPwd`, p);

// 提单列表
export const getBillsByOrderCode = (p) =>
  http.get(`/api/ladBill/getBillsByOrderCode?orderCode=${p.orderCode}`, p);

// 客户经理 /api/user/listCM
export const listCM = (p) => http.post(`/api/user/listCM`, p);

// 绑定客户经理 api/user/bindCustomerManager
export const bindCustomerManager = (p) =>
  http.post(`/api/user/bindCustomerManager`, p);

// 补充协议list
export const bizWareHouseContanctList = (p) =>
  http.get(`/api/data/list/biz_purchase_additional_contract?id=${p.id}`, p);

// 补充协议 commont api  自定义接口
export const bizCostomContanctList = (p) =>
  http.get(`/api/data/list/${p.path}?id=${p.id}`, p);

// 商品库存列表
export const wmsList = (p) =>
  http.get(
    `/api/data/list/wms_order_bill_data_list?orderCode=${p.orderCode}`,
    p
  );

//菜单服务
export const menuList = (pageName) =>
  http.get(`/api/menu/list?pageName=${pageName}`);

// 获取提单视频
export const vedioList = (billCode) =>
  http.get(`/api/ladBill/getVideoList?billCode=${billCode}`);

// 添加视频
export const addBillVedio = (p) => http.post(`/api/ladBill/addVideo`, p);

// 相机列表修改
export const listBindingInfoByCode = (p) =>
  http.get(
    `/api/monitor/listBindingInfoByCode?billCode=${p.billCode}&&orderCode=${p.orderCode}`
  );
// 相机列表
export const cameraList = (p) =>
  http.get(
    `/api/monitor/orderCodeBindMonitors?billCode=${p.billCode}&&orderCode=${p.orderCode}`
  );
// 摄像头接口
export const listBindingInfoByCodePage = (p) => http.post("/api/monitor/listBindingInfoByCodePage", p);
// 实时摄像头
export const OnlineVedioApi = (p) =>
  http.get(
    `/api/monitor/playInfo?deviceChannelNo=${p.deviceChannelNo}&&deviceSerialId=${p.deviceSerialId}`
  );

// 提单信息
const marks = [
  { id: "1", latAndLong: [53.041048, 5.792027] }, // 187
  { id: "2", latAndLong: [38.2, 120.11] },
  { id: "3", latAndLong: [37, 119.11] },
  { id: "4", latAndLong: [37, 119.11] },
  { id: "5", latAndLong: [37.5, 129.11] },
  { id: "6", latAndLong: [36.5, 130] },
  { id: "7", latAndLong: [34, 118.5] },
];
export const getOrders = () => marks;

// 查看报价中标
export const marketMatch = (p) => http.post("/api/market/match", p);

// 查看报价驳回
export const marketUnmatch = (p) => http.post("/api/market/unmatch", p);

// listclass 自定义 url
export const getListDataApi = (url) => http.get(url);

export const handleRemoveApi = (url) => http.post(url);

export const dashboardOrderList = (p) =>
  http.post("/api/bizOrder/unclosedOrders", p);
// 会话沟通是否被允许
export const isAllowCommunication = (p) =>
  http.get("/api/market/chat/check/" + p);

// 用户管理  禁用 user/changeStatus
export const userStatus = (p) => http.post(`/api/user/changeStatus`, p);
//撮合 消息 列表 /api/market/message/news
export const getMessageCenter = (p) => http.get(`/api/market/message/news`);
//// 撮合 消息 删除 /api/market/message/id
export const deteleMessageCenter = (p) => http.post(`/api/market/message/${p}`);
//工作台 货运方 产业商
export const purchaseAnd = (url, p) =>
  http.get("/api/metaSupport/searchRole/" + url + "?" + "requestParams=" + p);
//工作台 仓储商
export const getPurchaeStore = (p) =>
  http.get("/api/metaSupport/searchStore/" + "?" + "requestParams=" + p);
//工作台 新增业务单
export const AddBiz = (url, p) => http.post("/api/data/form/" + url, p);

export const getWarehouse = (p) => http.get("/api/metaSupport/getWarehouse/");
// 查看有没有权限提交报价
// /api/market/bid/check/
export const bidCheck = (p) => http.get("/api/market/bid/check/" + p);
// 中标撤回

export const undoMatch = (p) => http.post("/api/market/undoMatch", p);
///api/metaSupport/checkBillUser?billCode=338
export const checkBillUser = (p) =>
  http.get("/api/metaSupport/checkBillUser/" + "?" + "billCode=" + p);

// 代理进口需求  market/message/count
export const bidCheckNum = (p) => http.get("/api/market/message/count/" + p);

// 提单中转港删除
export const deleteBillTranshipment = (p) =>
  http.get("/api/metaSupport/deleteBillTranshipment/" + "?" + "id=" + p);

// 风险大脑
export const cerebrumUrl = () =>
  http.get("/api/market/ant/token");
//修改前企业信息
export const getUpdateDataId = (p) =>
  http.get("/api/user/getUpdateData/" + "?" + "id=" + p);
// 提单列表查看视频
export const ladBillDeleteVide = (p) =>
  http.get("/api/ladBill/deleteVide/?videoId=" + p);
//库存  `/api/monitor/playInfo?deviceChannelNo=${p.deviceChannelNo}&&deviceSerialId=${p.deviceSerialId}`
export const listBindingPicByCode = (p) =>
  http.get(`/api/monitor/listBindingPicByCode?orderCode=${p.orderCode}&&billCode=${p.billCode}`);
//仓库名称
export const getAllWarehouse = () => http.get("/api/metaSupport/getAllWarehouse");
//国家list
export const getContryList = (p) => http.post("/api/metaSupport/likeCountryList", p);
//船公司 
export const getlikeShipCompanyList = (p) => http.post("/api/metaSupport/likeShipCompanyList", p);
// 品名 货物种类
export const getcommodityList = (p) => http.post("/api/metaSupport/likeItemList", p);
// 新增订单?
export const setpostOrder = (p) => http.post("/api/visible/order/postOrder", p);
// 新增订单?POST

export const gettrajectory = (p) => http.post("/api/visible/cargoPosition/trajectory", p);
// 地图icon list
export const getpositionList = (p, apiHeader) => http.post("/api/visible/cargoPosition/position", p, {
  headers: {
    'H-VIEW-DEPARTMENT-CODE': apiHeader?.value || "",
  }
});
// 地图icon list-item
export const gettrackInfo = (p, apiHeader) => http.get(`/api/visible/cargoPosition/${p.type}/${p.traffic}`);
// 地图icon list-item, { headers: { 'H-VIEW-USER-CODE': apiHeader } }
export const gettrackInfoVisible = (p, apiHeader) => http.get(`/api/visible/cargoPosition/visible/${p.type}/${p.traffic}`);
// 地图icon list-item
export const gettrackInfoWorkflow = (p, apiHeader) => http.get(`/api/visible/cargoPosition/workflow/${p.type}/${p.traffic}`);
1

export const passwordReset = (p) => http.post(`/api/company/account/find/password`, p);





// 查看所有订单列表POST

export const getAllOrderList = (p) => http.post("/api/visible/order/getAllOrderList", p);
// POST 订单管理条件搜索
export const getOrderListByCon = (p) => http.post("/api/visible/order/getOrderListByCon", p);
// 查看单个订单_提交信息
export const getOrder = (p) => http.get(`/api/visible/order/getOrder?id=${p}`);
// 查看订单审核
export const getReviewOrder = (p) => http.get(`/api/visible/order/getReviewOrder?id=${p}`);
// 查看物流信息
export const getLogistics = (p) => http.get(`/api/visible/logistics/getLogistics?id=${p}`);
//  查看摄像头绑定信息
export const getMonitorBind = (p, apiHeader) => http.get(`/api/visible/monitor/getMonitorBind?orderCode=${p}`, {
  headers: {
    'H-VIEW-USER-CODE': apiHeader
  }
});
//  查看摄像头绑定信息
export const subsidiary = (p, apiHeader) => http.get(`/api/department/subsidiary`, {
  headers: {
    'H-VIEW-USER-CODE': apiHeader
  }
});
// 后台—查看提单附加
export const getOrderAddl = (p) => http.get(`/api/visible/order/getOrderAddl?id=${p}`);
// / api / visible / warehouse / getAllWarehouseList
// 查看所有仓库列表
export const getAllWarehouseList = (p) => http.post("/api/visible/warehouse/getAllWarehouseList", p);

// // 查看所有物流信息
export const getAllLogistics = (p) => http.post("/api/visible/logistics/getAllLogistics", p);

// 新增物流信息
export const postLogistics = (p) => http.post("/api/visible/logistics/postLogistics", p);
// 修改物流信息
export const putLogistics = (p) => http.post("/api/visible/logistics/putLogistics", p);

// 查询单个订单基础信息
export const getOrderBaseInfo = (p) => http.get(`/api/visible/order/getOrderBaseInfo?id=${p}`);
// 港口 下拉模糊搜索
export const likePortList = (p) => http.post("/api/metaSupport/likePortList", p);

// 仓库下拉模糊搜索
export const likeWhseList = (p) => http.post("/api/visible/warehouse/likeWhseList", p);
// 新增订单审核
export const putReviewOrder = (p) => http.post("/api/visible/order/putReviewOrder", p);
// POST
// 查看订单所有物流信息
export const getLogisticsByOrderCode = (p) => http.post("/api/visible/logistics/getLogisticsByOrderCode", p);

// 后台—订单启用 / 禁用
export const putLadingEnable = (p) => http.post("/api/visible/order/putLadingEnable", p);

// 后台—设置提单附加
export const putOrderAddl = (p) => http.post("/api/visible/order/putOrderAddl", p);
// 后台—修改到期日
export const putCloseTime = (p) => http.post("/api/visible/order/putCloseTime", p);
// 查看绑定列表
export const getOrderBindMonList = (p) => http.get(`/api/visible/monitor/getOrderBindMonList?orderCode=${p}`,);
// POST
// / api / visible / monitor / postMonitorBind
// 绑定摄像头
export const postMonitorBind = (p) => http.post("/api/visible/monitor/postMonitorBind", p);



// GET/api/visible/cargoPosition/mainRecord/{code} 停靠港口
export const mainRecord = (p) => http.post(`/api/visible/cargoPosition/mainRecord`, p);

// POST 修改订单
export const saveOrder = (p) => http.post(`/api/visible/order/save`, p);
// 新增物流信息
export const logisticsSave = (p) => http.post("/api/visible/logistics/save", p);
// 删除物流信息  DELETE
// /api/visi
// DELETE
// / api / visible / logistics / { id }
// 删除物流信息
export const deleteLogistics = (p) => http.delete(`/api/visible/logistics/${p}`,);
// 详情物流信息
export const logisticsGet = (p) => http.get(`/api/visible/logistics/${p}`);
// / api / visible / warehouse / getAllWarehouseList
// 修改仓库信息
export const putWarehouse = (p) => http.post("/api/visible/warehouse/putWarehouse", p);
// 新增仓库信息
export const postWarehouse = (p) => http.post("/api/visible/warehouse/postWarehouse", p);
// 查看仓库信息
export const wareGetWarehouse = (p) => http.get(`/api/visible/warehouse/getWarehouse?id=${p}`);
// 查看仓库监控
export const getWhseMonitor = (p) => http.get(`/api/visible/warehouse/getWhseMonitor?whseCode=${p}`);
// 查询单个订单_货物数量
export const getOrderItemNum = (p) => http.get(`/api/visible/order/getOrderItemNum?orderCode=${p}`);
// 仓储商条件
export const likeStoreUserList = (p) => http.post("/api/metaSupport/likeStoreUserList", p);
// 后台—查看到期日
export const getCloseTime = (p) => http.get(`/api/visible/order/getCloseTime?id=${p}`);
//历史订单
export const getOrderListHistory = (p) => http.post("/api/visible/order/getOrderListHistory", p);

//用户信息
export const getOrderUserInfo = (p) =>
  http.get("/api/visible/order/getOrderUserInfo/?id=" + p);
// 条件查询用户Code
export const getUserCodeByCon = (p) => http.get(`/api/visible/order/getUserCodeByCon?userCon=${p}`);
// 审核订单延期
export const putExtension = (p) => http.post("/api/visible/order/putExtension", p);
// 驾驶舱  所有业务 协作业务 非协业务
export const getbussinessList = (p, apiHeader) => http.post("/api/visible/bussiness/list", p, {
  headers: {
    'H-VIEW-USER-CODE': apiHeader
  }
})
export const getworkflowsList = (p, apiHeader) => http.post("/api/visible/bussiness/workflows", p, {
  headers: {
    'H-VIEW-USER-CODE': apiHeader
  }
})
export const getvisiblesList = (p, apiHeader) => http.post("/api/visible/bussiness/visibles", p, {
  headers: {
    'H-VIEW-USER-CODE': apiHeader
  }
})

// 业务进度 库存监控
export const getprogressList = (p, apiHeader) => http.get(`/api/bizOrder/process?orderCode=${p?.orderCode || ''}&billCode=${p?.billCode || ''}&ladingCode=${p?.ladingCode || ''}`, {
  headers: {
    'H-VIEW-USER-CODE': apiHeader
  }
})
export const getstoreList = (p, apiHeader) => http.get(`/api/metaSupport/wmsOverView?ladingCode=${p?.ladingCode}`, {
  headers: {
    'H-VIEW-USER-CODE': apiHeader
  }
})
// 我的雁群 协作单 /api/visible/bussiness/backend/workflows
export const backendWorkflows = (p) => http.post("/api/visible/bussiness/backend/workflows", p)
// 提单列表 api/ladBill/list
export const ladBillList = (p) => http.post("/api/ladBill/list", p)

//  setdetailUrl(`/api/bizOrder/deleteForCustom/${params.dataId}`);
export const deleteForCustom = (p) => http.post(`/api/bizOrder/deleteForCustom/${p}`)
//企业驾驶舱树 api/department/getCompanyTree
export const getCompanyTree = () =>http.get("/api/department/getCompanyTree");
  // http.get("/api/department/getEm");

export const bigscreen = () => http.get('/api/visible/statics/bigscreen')
// 编辑修改大屏数据
export const screenDataUpdData = (p) => http.post("/api/visible/ScreenData/updData", p);
// GET
//   / api / visible / ScreenData / list
// 列表查询
export const screenDataList = () => http.get('/api/visible/ScreenData/list')