import React, { useEffect, useRef } from 'react'
import * as echarts from 'echarts'
// import * as echarts from 'echarts/core';
import { CloseOutlined, } from '@ant-design/icons';
const Monthly = ({ xAxisData, metaDate, typeTabs, unit, unit1 }) => {


    const monthlyRef = useRef()
    var color = ['#60F4D9', '#3F96FF', '#8CA3B6', '#A982FF', '#00BFA5'];

    function hideMap() {
        console.log("点击了关闭按钮", monthlyRef.current)
        // myChart1._dom.childNodes[1].style.display = 'none'
    }
    var option = {
        color: ['#60F4D9', '#3F96FF', '#8CA3B6', '#A982FF', '#00BFA5'],
        tooltip: {
            //提示框组件
            trigger: "axis",
          
            axisPointer: {
                type: "",
                label: {
                    backgroundColor: "#6a7985",
                },
            },
            textStyle: {
                color: "#839AAA",
                fontFamily: 'df',
                fontSize: 10,
            },
        },
        legend: {
            data: [`出口(运量/${unit})`, `进口(运量/${unit1})`],
            itemHeight: 6, //修改icon图形大小
            itemWidth: 12,
            right: 10,
            textStyle: {                            //图例文字的样式
                color: function (params) {
                    console.log(params.dataIndex, "===--7778888--===")
                    return color[params.dataIndex]
                },          //图例文字颜色
                fontSize: 12,                   //图例文字大小
                fontFamily: 'df'
            },

        },
        grid: {
            top: '25%',
            left: '2%',
            right: '5%',
            bottom: '0%',
            containLabel: true,
        },

        xAxis: [
            {
                type: "category",
                data: [
                    "1月",
                    "2月",
                    "3月",
                    "4月",
                    "5月",
                    "6月",
                    "7月",
                    "8月",
                    "9月",
                    // "10月",
                    // "11月",
                    // "12月",
                ],
                // typeTabs == 'my' ? xAxisData : xAxisData,
                axisTick: { show: false },
                axisLabel: {
                    show: true,
                    textStyle: {
                        fontSize: 12,
                        color: '#839AAA',
                        fontFamily: 'df'
                    }
                }
            },
        ],
        yAxis: [
            {
                type: "value",
                min: 0,
                max: 500,
                splitNumber: 3,
                axisLabel: {
                    formatter: '{value}',
                    textStyle: {
                        color: '#839AAA',
                        fontSize: 12,
                        fontFamily: 'df',
                    }
                },
                axisLine: {
                    show: false,
                },
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: ["#fff"],
                        opacity: 0.06,
                    },
                },
            },
            {
                type: 'value',
                min: 0,
                max: 200,
                splitNumber: 3,
                axisLabel: {
                    formatter: '{value}',
                    textStyle: {
                        color: '#839AAA',
                        fontSize: 12,
                        fontFamily: 'df',
                    }
                },
                axisLine: {
                    show: false,
                },
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: ["#fff"],
                        opacity: 0.06,
                    },
                },
                position: 'right'
            },
        ],
        // series: seriesData,
        series: [
            {
                name: `出口(运量/${unit})`,
                type: 'bar',
                data: metaDate?.exportdata,

                barWidth: '20%',
                itemStyle: {
                    normal: {
                        color: function (params) {
                            return color[0]//[params.dataIndex]; //返回对应索引位置的颜色值
                        }
                    }
                },
            },
            {
                name: `进口(运量/${unit1})`,
                type: 'bar',
                data: metaDate?.importdata,
                yAxisIndex: 1,
                barWidth: '20%',
                itemStyle: {
                    normal: {
                        color: function (params) {
                            return color[1]//[params.dataIndex]; //返回对应索引位置的颜色值
                        }
                    }
                },
            },
        ]

    };


    useEffect(() => {
        const chart = echarts.init(monthlyRef.current)
        // 设置图表实例的配置项和数据
        const exportYaxisMax = metaDate?.exportdata?.reduce((max, item) => Math.max(max, item), 0);
        const importYaxisMax = metaDate?.importdata?.reduce((max, item) => Math.max(max, item), 0);

        option.yAxis[0].max = Math.floor(exportYaxisMax / 500) * 500 + 500;
        option.yAxis[1].max = Math.floor(importYaxisMax / 500) * 500 + 500
        chart.setOption(option, true)

        // 监听点击事件  
        const handleWindowClick = (event) => {
            // 点击窗口时，检查点击位置是否在图表区域内  
            chart.dispatchAction({
                type: "hideTip",
            });

        };
        window.addEventListener('click', handleWindowClick, true);
        setInterval(function () {
            //用setInterval做动画感觉有问题
            // draw();
        }, 100);
        // 组件卸载
        return () => {
            window.removeEventListener('click', handleWindowClick); 

        }
    }, [metaDate, typeTabs])
    return <div style={{ width: "100%", height: "100%" }} ref={monthlyRef}></div>;
}



export default Monthly;