
import './rightInventoryOverview.scss';
import { BorderBox1, ScrollBoard } from "@/utils/@jiaminghi/data-view-react"
import "@/index.scss";

import React, { useEffect, useState } from'react';
// const config = {
//     header: ['铁路货票号', '中车号', '当前状态'],
//     data: [
//         ['MLXZA0406931', '1557598', '<span style="color:#7ED321;">已到达</span>'],
//     ],
//     // index: true,///<span style="color:#e7bcf3;">行10列2</span>
//     // columnWidth: [100],
//     // align: ['center', 'center', 'center', 'center'],
//     // carousel: 'page'
//     columnWidth: [290, 140, 140, 130],
//     headerBGC: 'rgba(0, 123, 162, 0)',
//     headerHeight: '40',
//     oddRowBGC: 'rgba(0, 123, 162, 0)',
//     evenRowBGC: 'rgba(0, 123, 162, 0)',
//     rowNum: 3,
// }
function Index({ realTimeOrders }) {
    const [config, setConfig] = useState({
        header: ['铁路货票号', '中车号', '当前状态'],
        data: [],
        // index: true,///<span style="color:#e7bcf3;">行10列2</span>
        // columnWidth: [100],
        // align: ['center', 'center', 'center', 'center'],
        // carousel: 'page'
        columnWidth: [290, 140, 140, 130],
        headerBGC: 'rgba(0, 123, 162, 0)',
        headerHeight: '40',
        oddRowBGC: 'rgba(0, 123, 162, 0)',
        evenRowBGC: 'rgba(0, 123, 162, 0)',
        rowNum: 3,
    });
   
    useEffect(() => {
        // 当 realTimeOrders 变化时，更新 config.data  
        if (realTimeOrders) {
            setConfig(prevConfig => ({
                ...prevConfig,
                data: realTimeOrders.map((item, index) => [
                    item.railwayTicketNumber,
                    item.vehicleId,
                    `<span style="color:${item?.color || '#FFFFF'};">${item.currentStatus}</span>`
                    // item.currentStatus
                ])
            }));
        }
    }, [realTimeOrders]); 


    return (
        <div style={{ width: '100%' }}>
            <ScrollBoard config={config} style={{ width: '100%', }} oddRowBGC='#007ba233' />

        </div>
    );
}
export default Index