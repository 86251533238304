import * as echarts from 'echarts';
import { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import { geoJson, geoCoordMap } from "@/components/MapJson/word"
import { Modal, message, Table, Space, Button } from "antd"
// import ship from "@/assets/d_ship.png";
import ship from "@/assets/ship.svg";
import end_port from "@/assets/port.jpg";
import start_port from "@/assets/port.jpg";
import warehouse from '@/assets/d_s.jpg';
import { v4 as uuid } from "uuid"
import { getpositionList, gettrackInfo, getMonitorBind,getOnlinLists } from "@/api"
import "./index.scss"
import OnlineVeidoModal from "@/components/OnlineVedioModal"
import RenderShip from "./modal/renderShip"
import RenderStorage from "./modal/renderStorage"

const warehoustList=[
    {
        code: "WHSE014344",
        nameGoods:"泥炭",
        num1:10200,
        num2:175,
        lat: 21979129,
        lon: 71162736,
        posTime: 1721119699,
        status: null,
        title: "青州仓(恒晟仓)",
        traffic: "WHSE014344",
        type: "warehouse",
        typeMain: "warehouse",
    },
    {
        code: "WHSE343330",
        nameGoods:"泥炭",
        num1:10200,
        num2:175,
        lat: 21628438,
        lon: 72115887,
        posTime: 1721119699,
        status: null,
        title: "青岛仓（元康）",
        traffic: "WHSE343330",
        type: "warehouse",
        typeMain: "warehouse",
    },
    {
        code: "WHSE312955",
        nameGoods:"泥炭",
        num1:10200,
        num2:175,
        lat: 21619951,
        lon: 72127645,
        posTime: 1721119699,
        status: null,
        title: "青岛仓(恒顺丰)",
        traffic: "WHSE312955",
        type: "warehouse",
        typeMain: "warehouse",
    },

]
let enum_storage_type = {
    warehouse: "warehouse",
    port_start: "port",
    port_transhipment: "port",
    port_dest: "port",//port_transhipment", "port_dest"
    ship: "ship",
  };
export default function Map({ rateZoom, typeTabs, currentSubsidiary, showVedioInfo }) {
    const [isModalOpen, setisModalOpen] = useState(false);
    const [showVedioModal, setshowVedioModal] = useState(false);
    const [vedioInfo, setvedioInfo] = useState({});
    const [screenW, setscreenW] = useState(window.innerWidth);
    const [screenH, setscreenH] = useState(window.innerHeight);
    const [monitorList, setmonitorList] = useState([]);
    const [warehouseTitle, setwarehouseTitle] = useState('');
    const [markesList, setmarkesList] = useState([]);
    const [vedioList, setvedioList] = useState([]);
    const [modalTableData, setmodalTableData] = useState([{ id: 1, key: 1, address: "连云港", age: "铅锌矿", num: 288690 }]);
    const [customlabel, setcustomlabel] = useState({
        normal: {
            distance: .1,
            position: 'top',
            fontSize: 8,
            padding: [6, 6, 6, 6],
            show: true,//是否显示地名
            formatter: function (p) {
                return `{a|${p.name}}`
            },
            rich: {
                a: {
                    fontSize: 6,
                    color: '#fff',
                    backgroundColor: '#000',
                }
            }
        },

    });
    const columns = [
        {
            title: '货物名称',
            dataIndex: 'nameGoods',
            key: 'name1',

        },

        {
            title: '数量(包)',
            dataIndex: "num1",
            key: 'name2',
        },
        {
            title: '数量(托)',
            dataIndex: "num2",
            key: 'name11',
        },
       
     

    ];
    const [serviesItem, setserviesItem] = useState({
        tooltip: {
            trigger: 'item',
            triggerOn: 'click',
            enterable: true,
            show: true,
            zlevel: 100,
            zIndex: 100,
            formatter: function (params, ticket, callback) {

                let m = params.value[2];
                if(['WHSE014344',"WHSE343330",'WHSE312955'].includes(m?.traffic )){
                  return  showDetail(m);
                }
               
                (async () => {
                    let { data } = await gettrackInfo({ type: m?.type, traffic: m?.traffic });
                    callback(ticket, renderModal(data, m?.type, m?.title,m))

                })()
                let temp = `<div>加载中...... </div>`
                return temp;
            },

        },
        symbol: function (p) {

            let image = end_port
            if (p[2].type == 'port_start') {
                image = start_port
            }
            if (p[2].type == 'ship') {
                image = ship
            }
            if (p[2].type == 'warehouse') {
                image = warehouse
            }

            return `image://${image}`
        },
        symbolStyle: {

            opacity: 1,
        },
        symbolSize: 35 * rateZoom,
        label: {
            normal: {

                distance: .1,
                position: 'top',
                padding: [6, 6, 6, 6],
                show: true,//是否显示地名
                formatter: function (p) {
                    return `{a|${p.name}}`
                },
                rich: {
                    a: {
                        fontSize: 6,
                        color: '#fff',
                        backgroundColor: '#000',
                    }
                }
            },

        },
        itemStyle: {
            color: '#000', // 设置散点的背景色为红色，无透明度  
            borderColor: '#000000', // 设置散点的边框色为黑色  
            borderWidth: 122,// 设置散点的边框宽度为 1  
            opacity: 1,
        },
        name: 'light',
        type: 'scatter',
        coordinateSystem: 'geo',
        showEffectOn: 'render',
        data: []
    });
    const [option, _] = useState({

        color: [
            '#73b9bc',
            '#7289ab',
            '#91ca8c',
            '#f49f42'
        ],
        tooltip: {
            trigger: 'item',
            zindex: 1001,
            className: 'demo_custom_tooltip',
            alwaysShowContent: true,
            triggerOn: 'click',
            show: false,
            formatter: function (params) {

                if (typeof params.value[2] == 'undefined') {
                    return ''
                } else {
                    let temp = `<div>
            <h2>${params.value[3].code} </h2>
            ${params.value[3].title}
            </div>`
                    return temp;
                }
            },
        },

        geo: [
            {
                map: 'world',
                aspectScale: 1,
                layoutCenter: ['48.2%', '52%'], //地图位置
                layoutSize: '100%',
                roam: true,
                geoIndex: 0,
                itemStyle: {
                    borderColor: 'rgba(36,155,184,.1)',
                    borderWidth: 1,
                    shadowColor: "rgba(34, 202, 255, 0.1)",
                    shadowBlur: 10,
                    shadowOffsetX: 10,
                    shadowOffsetY: 0,
                    areaColor: {
                        type: 'radial',
                        x: screenW / 2,
                        y: screenH / 2,
                        r: 200,
                        colorStops: [{
                            offset: 0.1,
                            color: '#01BDA9' // 0% 处的颜色
                        }, {
                            offset: 1,
                            color: '#085068' // 50% 处的颜色
                        }],
                        global: true // 缺省为 false
                    },
                    emphasis: {
                        disabled: true,
                    }
                },
                emphasis: {
                    disabled: true
                }
            },
            // {
            //     map: 'world',
            //     aspectScale: 0.76,
            //     layoutCenter: ['50%', '48%'], //地图位置
            //     layoutSize: '90%',

            //     roam: "none",
            //     zoom: 1,
            //     geoIndex: 1,
            //     itemStyle: {
            //         borderColor: 'rgba(36,155,184,1)',
            //         borderWidth: 1,

            //         areaColor: {
            //             type: 'radial',
            //             x: screenW / 2,
            //             y: screenH / 2,
            //             r: 200,
            //             colorStops: [{
            //                 offset: 0.1,
            //                 color: '#01BDA9' // 0% 处的颜色
            //             }, {
            //                 offset: 1,
            //                 color: '#007BA2' // 50% 处的颜色
            //             }],
            //             global: true // 缺省为 false
            //         },
            //         emphasis: {
            //             disabled: true,
            //         }
            //     },
            //     emphasis: {
            //         disabled: true
            //     }
            // }
        ],
        series: [
            {
                type: 'effectScatter',
                coordinateSystem: 'geo',

                z: 0,
                rippleEffect: { //涟漪特效
                    period: 15, //动画时间，值越小速度越快
                    brushType: 'stroke', //波纹绘制方式 stroke, fill
                    scale: 6 //波纹圆环最大限制，值越大波纹越大
                },

                symbol: 'circle',
                symbolSize: function (val) {
                    return 12; //圆环大小
                },
                itemStyle: {
                    normal: {
                        show: true,
                        color: 'red' // 字体颜色
                        // color: '#0081E7' // 字体颜色
                    }
                },
                data: []
            },
        ],

    })
    let timer = useRef(null);
    useEffect(() => {
        getMarkers()

    }, [typeTabs, currentSubsidiary])
    useEffect(() => {

        console.log('showVedioInfo', showVedioInfo, markesList);
        let currenPort = markesList?.find(f => f?.title?.includes(showVedioInfo));
        if (currenPort?.code) {
            // handleVedio(currenPort?.code);

        }
    }, [showVedioInfo])
    const getMarkers = async () => {
        const domEchart=document.getElementById('demo_echarts');
        
        const myChart = echarts.init(domEchart);
        // EventMyChart(domEchart)
        isTouchDevice();
        echarts.registerMap('world', geoJson);
        option.geo[0].zoom = typeTabs == "ck" ? 1 : 1.3;
        if (option.geo.length > 1) {

            option.geo[1].zoom = typeTabs == "ck" ? 1 : 1.3;
        }
        myChart.setOption(option)
        const iconZoom = typeTabs == "ck" ? 1 : 0.95;
        myChart.on("click", function (params) {
            // myChart.setOption(option); // 更新图表配置  
            // if (params.componentType == "geo") {
         console.log(params, 'params');
            // 隐藏提示框  
            renderModal([]);
            myChart.dispatchAction({
                type: 'hideTip'
            });
            // }
        })
        if (timer) clearInterval(timer);
        timer = setInterval(() => {
            getMarkers()
        }, 1000 * 60 * 60)
        try {
            const { data } = await getpositionList({ "pageNum": 1, "pageSize": 200 }, currentSubsidiary);
            let list = data?.list || []
// 优先前端自定义的
            list = list?.map(m => {
                let custom = warehoustList?.find(f => f?.code == m?.code);
                if (custom) {
                    return {...m,...custom }
                } else {
                    return m
                }
            })
            // 优先后端自定义的
            // list = list?.map(m => {
            //     let custom
            // setmarkesList(list); 
            // setmarkesList([{id:1}]);
            if (list.length > 0) {
                let servies = [{
                    type: 'effectScatter',
                    coordinateSystem: 'geo',

                    z: 0,
                    rippleEffect: { //涟漪特效
                        period: 15, //动画时间，值越小速度越快
                        brushType: 'stroke', //波纹绘制方式 stroke, fill
                        scale: 7 //波纹圆环最大限制，值越大波纹越大
                    },

                    symbol: 'circle',
                    symbolSize: function (val) {
                        return 12; //圆环大小
                    },
                    itemStyle: {
                        normal: {
                            show: true,
                            color: 'rgba(255,255,0,0.6)' // 字体颜色
                            // color: '#0081E7' // 字体颜色
                        }
                    },
                    data: []
                }]
                let markers = list.filter(f => f.type == "ship").map(m => ({ name: m?.title, value: [m?.lon / 600000, m?.lat / 600000, m] }));

                // 货源地 port_start 船 ship 国内港口 port_dest 
                servies[0].data = markers
                let shi_data = list.filter(m => m?.type == "ship").map(m => ({ name: m?.title, value: [m?.lon / 600000, m?.lat / 600000, m] }));

                let ship_labe = { ...customlabel, normal: { ...customlabel.normal } }
                servies.unshift({ ...serviesItem, symbolSize: 40 * rateZoom, label: ship_labe, data: shi_data })

                //port_start
                let start_data = list.filter(m => ["port_transhipment", "port_dest", "port_start"].includes(m?.type)).map(m => ({ name: m?.title, value: [m?.lon / 600000, m?.lat / 600000, m] }));
                let start_label = { ...customlabel, normal: { ...customlabel.normal, position: 'bottom' } }
                servies.unshift({ ...serviesItem, symbolSize: 40 * rateZoom * iconZoom, label: start_label, data: start_data })
            //warehouse

                let end_data = [...list,...warehoustList].filter(m => m?.type == "warehouse").map(m => ({ name: m?.title, value: [m?.lon / 600000, m?.lat / 600000, m] }));

                servies.unshift({ ...serviesItem, symbolSize: 40 * rateZoom * iconZoom, label: { ...customlabel }, data: end_data })

                option.series = servies
                myChart.setOption(option)
                // debugger

            } else {
                // option.series[0].data = []
                // myChart.setOption(option)
            }
        } catch (error) {
            console.log('api-getpositionList', error);
        }


    }
    function isTouchDevice() {
        return ('ontouchstart' in window) || 
               (navigator.maxTouchPoints > 0) ||
               (navigator.msMaxTouchPoints > 0);
    }
    
    // 使用该函数
    if (isTouchDevice()) {
        console.log('当前浏览器运行环境支持触摸事件，可能具有触摸板或触摸屏');
    } else {
       console.log('当前浏览器运行环境不支持触摸事件，或不具有触摸板或触摸屏');
    }
const EventMyChart=(edom)=>{
    edom.addEventListener("touchmove",function(e){
     e.preventDefault(); // 阻止默认行为  
        console.log('e',e?.targetTouches);
    },{passive:false})
}
    // 实时监控 = 215419000
    const handleVedio = async (m) => {
        console.log(m, 'mm==');
      
            setshowVedioModal(true)
            setvedioInfo({
                vid: uuid(),
                pic: m?.url,
                deviceChannelNo: m?.deviceChannelNo,
                deviceSerialId: m?.deviceSerialId
            })
        
        try { } catch (error) {
            console.log(data, "  ==--getMonitorBind--============")
        }



    }

    const showDetail = async(info) => {
        console.log(info, 'info');
        setwarehouseTitle(info?.title);
        const {data}=await getOnlinLists(info?.traffic);
        setvedioList(data);
        console.log(warehoustList,'==markesList vinfo==', info);
        setisModalOpen(true);
        // setmonitorList([warehoustList.find(f => f.title == info[0]?.warehouse)]);
    }
    const renderModal = (billList, type, title,markerInfo) => {

        setTimeout(() => {
          
            const container = document.getElementById('cooperator_modal');
            let root = null;
            if (container && container instanceof HTMLElement) {
                root =root?root: ReactDOM.createRoot(container);  
                // if (container) {
                //     root = ReactDOM?.createRoot?.(
                //         document.getElementById('cooperator_modal')
                //     );
                // }
            console.log(root, 'root');
                if (root&&billList?.length) {
                    // 在 Tooltip 中渲染 React 组件  
                    console.log(type, '====billList', billList);

                    root.render((() => {
                        if (billList?.length == 1) {
                            if (type != "hz") {

                                return <RenderShip props={billList[0]} storageType={enum_storage_type[type]} showDetail={() => showDetail(markerInfo)}/>
                            }
                            //堆场
                            if (type !== "warehouse") {
                                return <RenderStorage
                                    item={billList[0]}
                                    title={title}
                                    showDetail={() => showDetail(markerInfo)}
                                    storageType={type} />
                            }
                            // 起始港数据 暂定为house
                            return <RenderStorage storageType={type} title={title} item={billList[0]} showDetail={() => showDetail(markerInfo)} />
                        }
                        if (billList?.length > 1) {

                            return <RenderStorage storageType={enum_storage_type[type]} title={title} billList={billList} showDetail={() => showDetail(markerInfo)} handleVedio={(m) => handleVedio(m)} />
                        }
                        return <div></div>



                    })());


                }
                if(billList?.length==0){
                    root.render(<div></div>)
                }
            } else {
                console.log("Target container is not a DOM element.");
            }
           
        }, 0);
        
        // 返回一个空的 div 作为 Tooltip 的占位符  
        return '<div id="cooperator_modal"></div>';

    }



    return (
        <>
            <div id="demo_echarts" key="0" style={{ width: screenW, marginTop: typeTabs == "ck" ? 0 : 0 }}></div>
            {/* {typeTabs == "ck" ? <div id="demo_echarts" key="0" style={{ width: screenW}}></div>
                : <div id="demo_echarts" key="1" style={{ width: 800*screenW/1920}}></div>} */}

            <Modal
                zIndex="99999990"
                title={<div className='demo_detail_title'> 
                <span>{ warehouseTitle}</span>
                <div className='demo_detail_title_btn'>
                    {vedioList.map(m=>(<span onClick={() => handleVedio(m)} style={{cursor:"pointer"}}>{m.name}</span>))}
                </div>
                </div>}
                className="cooperator_demo_detail_modal"
                open={isModalOpen}
                mask={false}
                closable={true}
                footer={null}
                onCancel={() => setisModalOpen(false)}
                width={400}
                style={{ right: 0, top: 140 }}
                bodyStyle={{
                    background: '#062D39',
                }}
            >

                <Table
                    className="demo_modal_table "
                    size="small"
                    pagination={false}
                    columns={columns}
                    dataSource={warehoustList.map(m => ({ id: m?.code, key: m?.code,  nameGoods: m?.nameGoods, num1: m?.num1, num2: m?.num2 }))} />

            </Modal>
            {/* 实时监控 */}
            {showVedioModal && <OnlineVeidoModal
                {...vedioInfo}
            />}
        </>
    )
}